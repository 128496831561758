import { useState } from 'react';

import { formatedQuantityToNumber, isNumber } from 'lib/number';

export const desc = (a, b, orderBy) => {
  let v1 = a[orderBy]?.toString().toLowerCase() ?? '-';
  let v2 = b[orderBy]?.toString().toLowerCase() ?? '-';

  if (typeof a[orderBy] === 'object' && typeof b[orderBy] === 'object') return;

  if (!orderBy.toLowerCase().includes('hash')) {
    if (isNumber(a[orderBy])) {
      v1 = +a[orderBy];
      v2 = +b[orderBy];
    } else if (typeof formatedQuantityToNumber(a[orderBy]) === 'number' || typeof formatedQuantityToNumber(b[orderBy]) === 'number') {
      v1 = formatedQuantityToNumber(a[orderBy]) ?? 0;
      v2 = formatedQuantityToNumber(b[orderBy]) ?? 0;
    }
  }

  if (v2 < v1) {
    return -1;
  } else if (v2 > v1) {
    return 1;
  } else {
    return 0;
  }
};

const getTime = (date) => {
  if (date?.indexOf(':') === -1) {
    return new Date(date.split('.').reverse().join('/')).getTime();
  }
  if (date?.indexOf(':') > -1) {
    let dateTime = date.split(' ');
    let newDate = [ dateTime[0].split('.').reverse().join('/'), dateTime[1] ].join(' ');
    return new Date(newDate).getTime();
  }
  return 0;
};

const descDate = (a, b, orderBy) => {
  if (getTime(b[orderBy]) < getTime(a[orderBy])) {
    return -1;
  }
  if (getTime(b[orderBy]) > getTime(a[orderBy])) {
    return 1;
  }
  return 0;
};

export const getSorting = (order, orderBy) => {
  if (orderBy.toLowerCase().includes('date')) {
    return order === 'desc' ? (a, b) => descDate(a, b, orderBy) : (a, b) => -descDate(a, b, orderBy);
  }
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
};

export const stableSort = (array = [], orderBy, cmp, arrayForSortInit = null) => {
  let stabilizedThis = [];
  let skippedValues = [];
  let arrayForSort = arrayForSortInit ?? [ ...array ];
  array?.forEach((el, index) => {
    if (el[orderBy] || el[orderBy] === 0) {
      stabilizedThis.push([ el, index ]);
    } else {
      skippedValues.push([ el, index ]);
    }
  });

  stabilizedThis.sort((a, b) => {
    const order = cmp(arrayForSort[a[1]], arrayForSort[b[1]]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.concat(skippedValues).map(el => el[0]);
};

export const useTableSort = () => {
  const [ order, setOrder ] = useState('asc');
  const [ orderBy, setOrderBy ] = useState('');

  const createSortHandler = property => () => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  return { order, orderBy, createSortHandler };
};
