import { handleFetching } from 'symbiote-fetching';

import { getResponse } from 'features/common';

import { actions } from './symbiotes';
import { mapProgramsAll } from './mappers';
import { dictionariesApi } from './api';

export const getInstrumentTypes = () =>
  handleFetching(actions.fetchGetInstrumentTypes, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(dictionariesApi.getInstrumentTypes);

        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.saveInstrumentTypes(data));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getAvailableStatuses = () =>
  handleFetching(actions.fetchGetInstrumentTypes, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(dictionariesApi.getAvailableStatuses);

        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.saveStatuses(data));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getInstrumentTypesDS = () =>
  handleFetching(actions.fetchGetInstrumentTypesDS, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(dictionariesApi.getInstrumentTypesDS);

        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.saveInstrumentTypesDS(data));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getMarkupTypes = () =>
  handleFetching(actions.fetchGetMarkupTypes, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(dictionariesApi.getMarkupTypes);

        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.saveMarkupTypes(data));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getCommissionTypes = () =>
  handleFetching(actions.fetchGetMarkupTypes, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(dictionariesApi.getCommissionTypes);

        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.saveCommissionTypes(data));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getSymbolTypes = () =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(dictionariesApi.getSymbolTypes);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.saveSymbolTypes(response));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getBlockchains = () =>
  handleFetching(actions.fetchGetBlockchains, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(dictionariesApi.getBlockchains);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.saveBlockchains(data));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getMarketingProgramsAll = () =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(dictionariesApi.getMarketingProgramsAll);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          dispatch(actions.setProgramsAll(mapProgramsAll(response.source_data)));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });
