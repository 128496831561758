const NAN = '--';

export const AssetType = {
  DCR: 'DCR',
  DGR: 'DGR',
  FRT: 'FRT',
  VNX: 'VNX',
  CURRENCY: 'Currency',
  CRYPTO: 'Crypto',
  INT: 'Int',
  FLOAT: 'Float',
  ALTCOIN: 'AltCoin',
  GOLD_PRICE: 'GoldPrice',
  STRUCTURED_DEPOSIT: 'StructuredDeposit',
  OBLIGATION: 'Obligation',
};

export const convertNumber = (type, item) => {
  if (type === AssetType.CURRENCY) return asCurrency(item);
  if (type === AssetType.DCR) return asFloat(item);
  if (type === AssetType.DGR) return asCrypto(item);
  if (type === AssetType.FRT) return asCrypto(item);
  if (type === AssetType.VNX) return asCrypto(item);
  if (type === AssetType.CRYPTO) return asCrypto(item);
  if (type === AssetType.ALTCOIN) return asCrypto(item);
  if (type === AssetType.INT) return asInteger(item);
  if (type === AssetType.FLOAT) return asFloat(item);
  if (type === AssetType.GOLD_PRICE) return asGoldPrice(item);
  if (type === AssetType.STRUCTURED_DEPOSIT) return asCrypto(item);
  if (type === AssetType.OBLIGATION) return asCrypto(item);
  return asFloat(item);
};

export function asInteger(n) {
  if (!isNaN(Number(n))) {
    return Intl.NumberFormat('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(n);
  } else {
    return NAN;
  }
}

export function asFloat(n) {
  if (!isNaN(Number(n))) {
    return Intl.NumberFormat('fr-LU', { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(n);
  } else {
    return NAN;
  }
}

export function asCurrency(n) {
  if (!isNaN(Number(n))) {
    return Intl.NumberFormat('fr-LU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(n);
  } else {
    return NAN;
  }
}

export function asCrypto(n, fractionDigits = 5) {
  if (!isNaN(Number(n))) {
    return Intl.NumberFormat('fr-LU', { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }).format(n);
  } else {
    return NAN;
  }
}

export function asPercents(n) {
  if (!isNaN(Number(n))) {
    return Intl.NumberFormat('fr-LU', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(n / 100);
  } else {
    return NAN;
  }
}

export function asGoldPrice(n) {
  if (!isNaN(Number(n))) {
    return Intl.NumberFormat('fr-LU', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(n);
  } else {
    return NAN;
  }
}

export function range(start, end) {
  let arr = [];
  for (let i = start; i < end; i++) {
    arr.push(i + 1);
  }
  return arr;
}

export function phoneNumberOutput(number) {
  if (number?.length > 0) {
    return '+' + [ number.slice(0, 1), number.slice(1, 4), number.slice(4, 7), number.slice(7) ].join(' ');
  }
}

export function isNumber(value) {
  return !isNaN(value);
}

export const formatNumber = (value) => {
  if (value) {
    return value.toString().replace(',', '.');
  }
  return 0;
};

export function asFrtPrice(n) {
  if (!isNaN(Number(n))) {
    return Intl.NumberFormat('fr-LU', { minimumFractionDigits: 5, maximumFractionDigits: 10 }).format(n);
  } else {
    return NAN;
  }
}

export const formatedQuantityToNumber = (str) => {
  if (str === undefined || str === null) return null;
  if (isNumber(str)) return Number(str);
  if (str === NAN) return -1;

  let newStr = str.replace(/[a-z]/gi, '');
  if (newStr.includes('.') ) {
    newStr = newStr.replaceAll('.', '');
  }

  if (isNumber(Number(newStr.replaceAll(',', '.')))) {
    return Number(newStr.replaceAll(',', '.'));
  } else {
    return str;
  }
};
