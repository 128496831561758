import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchBankDetails: initialFetching,
  fetchClientBankDetails: initialFetching,
  fetchDepositWallets: initialFetching,
  bankDetails: [],
  clientBankDetails: [],
  clientBeneficiariesBankDetails: [],
  clientCryptoWalletList: [],
  depositWallets: [],
  vasps: [],
};

const symbiote = {
  fetchBankDetails: createFetching('fetchBankDetails'),
  fetchDepositWallets: createFetching('fetchDepositWallets'),
  fetchClientBankDetails: createFetching('fetchClientBankDetails'),
  fetchClientCryptoWalletList: createFetching('fetchClientCryptoWalletList'),
  setBankDetails: (state, data) => ({ ...state, bankDetails: data }),
  setClientBankDetails: (state, data) => ({ ...state, clientBankDetails: data }),
  setClientBeneficiariesBankDetails: (state, data) => ({ ...state, clientBeneficiariesBankDetails: data }),
  setClientCryptoWalletList: (state, data) => ({ ...state, clientCryptoWalletList: data }),
  setDepositWallets: (state, data) => ({ ...state, depositWallets: data }),
  setVasps: (state, data) => ({ ...state, vasps: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'paymentDestinations');
