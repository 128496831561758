import { api } from 'features/common';

const DICT_PREFIX = '/admin/dict';

export const dictionariesApi = {
  getAssetTypes: () => api.get(`${DICT_PREFIX}/assetTypes`),
  getSymbolTypes: () => api.get('/admin/transactions/avail-symbol-types'),
  getCountries: () => api.get(`${DICT_PREFIX}/countries`),
  getIdTypesKycs: () => api.get(`${DICT_PREFIX}/idTypeKYCs`),
  getIdTypeNationals: () => api.get(`${DICT_PREFIX}/idTypeNationals`),
  getInvestmentStages: () => api.get(`${DICT_PREFIX}/investmentStages`),
  getIdTypes: country => api.get(`/admin/idTypes${country ? '?Country=' + country : ''}`),
  getCCY: active => api.get(`${DICT_PREFIX}/currencies?Active=${Boolean(active)}`),
  getInstrumentTypes: () => api.get('/admin/offering/instrument-types'),
  getInstrumentTypesDS: () => api.get('/admin/direct-sale/instrument-types'),
  getAvailableBookBlockchainStatuses: query => api.get('/admin/offering/blockchain/books/bc-statuses', query),
  getAvailableBookBlockchainTxTypes: () => api.get('/admin/offering/blockchain/books/tx-types'),
  getAvailableBidBlockchainTxTypes: () => api.get('/admin/offering/blockchain/bids/tx-types'),
  getAvailableBidBlockchainBusinessStatuses: () => api.get('/admin/offering/blockchain/bids/business-statuses'),
  getAvailableDCRBlockchainStatuses: query => api.get('/admin/dcr/bc-statuses', query),
  getAvailableDCRBlockchainTxTypes: () => api.get('/admin/dcr/dcrs/tx-types'),
  getAvailableTradeBlockchainTxTypes: () => api.get('/admin/dcr/trades/tx-types'),
  getAvailableTransferBlockchainTxTypes: () => api.get('/admin/bc-transfer/tx-types'),
  getAvailableDCRTickers: () => api.get('/admin/dcr/tickers'),
  getAvailableTransferTickers: () => api.get('/admin/bc-transfer/tickers'),
  getMarkupTypes: () => api.get('/admin/direct-sale/markup-types'),
  getCommissionTypes: () => api.get('/admin/direct-sale/commission-types'),
  getAvailableTransactionTypes: () => api.get('/admin/transactions/avail-types'),
  getAvailableSymbolTypes: () => api.get('/admin/transactions/avail-symbol-types'),
  getAvailableStatuses: () => api.get('/admin/transactions/avail-statuses'),
  getAvailableTradeTypes: () => api.get('/admin/direct-sale/trade-types'),
  getBalancingTransactionsAvailableTypes: () => api.get('/admin/transactions/balancing/avail-types'),
  getBlockchains: () => api.get('/admin/crypto/blockchains'),
  getOrderSources: () => api.get('/admin/orders/sources'),
  getMarketingProgramsAll: () => api.get('/admin/marketing-program/all'),
};
