
import { TransactionsReportStatus } from 'features/transactions/consts';

export function fileDownload(data, name, ext) {
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = data.url;
  link.download = `${name}.${ext}`;
  link.click();
  document.body.removeChild(link);
}

export function downloadStringToFile(text, fileType, fileName) {
  let blob = new Blob([ text ], { type: fileType });

  let a = document.createElement('a');
  a.download = `${fileName}.${fileType}`;
  a.href = URL.createObjectURL(blob);
  a.dataset.downloadurl = [ fileType, a.download, a.href ].join(':');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function () {
    URL.revokeObjectURL(a.href);
  }, 1500);
}

export const handleProcessingReport = (data, getStatusCb, getFile, setReport, setError, setErrorShow, options = { fileName: 'Transactions report', fileExt: 'xlsx' }) => {
  let timeout = 1000;
  let isSaved = false;
  setReport({ active: false, data: null, progress: 0 });
  const { fileName, status } = data;
  const saveFile = async (name) => {
    const responce = await getFile(name);
    fileDownload(responce.data, options.fileName, options.fileExt);
    setReport({ active: false, data: null, progress: 0 });
  };

  const showError = () => {
    setError('An error occurred while generating the report');
    setErrorShow(true);
    setReport({ active: false, data: null, progress: 0 });
  };

  if (status === TransactionsReportStatus.NOT_EXIST) {
    showError();
    setReport({ active: false, data: null, progress: 0 });
  } else if (status === TransactionsReportStatus.COMPLETED){
    saveFile(fileName);
  } else {
    const maxTimeout = 10000;
    const delays = [ 1000, 3000, 5000 ];
    let index = 0;
    let iterations = 1;
    const checkFunc = async () => {
      if (iterations === 3 && index !== 2) {
        iterations = 1;
        index++;
        timeout = delays[index];
      } else if (iterations === 3 && index === 2) {
        timeout = maxTimeout;
      } else {
        iterations++;
      }
      const response = await getStatusCb({ fileName });
      if (response?.data?.status === TransactionsReportStatus.NOT_EXIST) {
        showError();
        clearTimeout(timer);
      } else if (response?.data?.status === TransactionsReportStatus.COMPLETED && !isSaved) {
        isSaved = true;
        saveFile(response?.data?.fileName);
        clearTimeout(timer);
      } else {
        setReport({ active: true, data, progress: Number(response?.data?.percent) });
        setTimeout(checkFunc, Math.min(maxTimeout, response?.data?.percent < Number(95) ? timeout : 3000));
      }
    };
    const timer = setTimeout(checkFunc, Math.min(maxTimeout, timeout));
  }
};
