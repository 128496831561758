import { createSelector } from 'reselect';

import { ClientTypeForm } from './models';

export const clientsRootSelector = createSelector(
  root => root.clients,
  clients => clients
);

export const clientSelector = createSelector(clientsRootSelector, clients => clients.client);
export const clientBalanceSelector = createSelector(clientsRootSelector, clients => clients.clientBalance);
export const clientsSelector = createSelector(clientsRootSelector, clients => clients.clients);
export const providersSelector = createSelector(clientsSelector, clients => clients.filter(client => client.type !== 'unknown' && client.clientType !== ClientTypeForm.MARKET_OPERATOR));
export const deletedClientsSelector = createSelector(clientsRootSelector, clients => clients.deletedClients);

export const accessOfferings = createSelector(clientsRootSelector, clients => clients.accessOfferings);

export const clientsAccessOffering = createSelector(clientsRootSelector, clients => clients.clientsAccessOffering);

export const recalculatePositionsStatusSelector = createSelector(clientsRootSelector, clients => clients.recalculatePositionsStatus);

export const recalculatePositionsLoadingSelector = createSelector(clientsRootSelector, clients => clients.fetchRecalculatePositions.status);

export const clientTermsSelector = createSelector(clientsRootSelector, clients => clients.clientTerms);
export const clientExpireDateSelector = createSelector(clientsRootSelector, clients => clients.expireDate);
export const clientCopiedDetailsSelector = createSelector(clientsRootSelector, clients => clients.copiedDetails);
export const activeClientsFilterSelector = createSelector(clientsRootSelector, clients => clients.activeClientsFilter);
export const deletedClientsFilterSelector = createSelector(clientsRootSelector, clients => clients.deletedClientsFilter);
export const clientsReportSelector = createSelector(clientsRootSelector, clients => clients.clientsReport);
