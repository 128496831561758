import { withStyles } from 'tss-react/mui';
import React, { useEffect, useRef, useState } from 'react';
import { Grid, Popover, Typography } from '@mui/material';

const style = (theme, { blockchainStatus }) => ({
  status: {
    '&::before' : {
      content: '"⬤"',
      marginRight: '5px',
      color: blockchainStatus ? 'green' : 'red',
    },
  },
  hiddenBlock: {
    opacity: 0,
    height: 0,
    pointerEvent: 'none',
    zIndex: -10,
  },
  showMore: {
    cursor: 'pointer',
  },
});

const BlockchainStatusView = ({ classes, blockchain }) => <Typography className={classes.status} variant="body1">{blockchain}</Typography>;

const BlockchainStatus = withStyles(BlockchainStatusView, style);

const BlockchainsStatusBarView = ({ classes, blockchains }) => {
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ visibleCount, setVisibleCount ] = useState(blockchains?.length);
  const containerRef = useRef(null);

  useEffect(() => {
    const calculateVisibleLabels = () => {
      const containerWidth = containerRef.current.offsetWidth;
      let totalWidth = 0;
      let count = 0;

      for (let label of containerRef.current.children) {
        totalWidth += label.offsetWidth;
        if (totalWidth <= containerWidth) {
          count += 1;
        } else {
          break;
        }
      }
      if (count !== blockchains.length) {
        count = count === 0 ? count : count - 1;
      }
      setVisibleCount(count);
    };

    calculateVisibleLabels();
    window.addEventListener('resize', calculateVisibleLabels);
    return () => window.removeEventListener('resize', calculateVisibleLabels);
  }, [ blockchains ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  return (
    <Grid container>
      <Grid container direction='row' justifyContent='flex-end' spacing={2}>
        {
          blockchains.slice(0, visibleCount).map(blockchain => (
            <Grid item alignItems='center' key={blockchain.BlockchainId}>
              <BlockchainStatus blockchain={blockchain.BlockchainId} blockchainStatus={blockchain.Status}/>
            </Grid>
          ))
        }
        {
          visibleCount < blockchains.length && (
            <>
              <Grid item alignItems='center' aria-describedby={id} className={classes.showMore} onClick={handleClick}>
                <Typography>
                  +{blockchains.length - visibleCount}
                </Typography>
              </Grid>
              <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                id={id}
                open={open}
                onClose={handleClose}
              >

                <Typography sx={{ p: 2 }}>
                  {blockchains.slice(visibleCount).map(blockchain => (
                    <BlockchainStatus blockchain={blockchain.BlockchainId} blockchainStatus={blockchain.Status} key={blockchain.BlockchainId}/>
                  ))}
                </Typography>
              </Popover>
            </>
          )
        }
      </Grid>

      <Grid container className={classes.hiddenBlock} direction='row' justifyContent='flex-end' ref={containerRef} spacing={3}>
        {
          blockchains.map(blockchain => (
            <Grid item alignItems='center' key={blockchain.BlockchainId}>
              <BlockchainStatus blockchain={blockchain.BlockchainId} blockchainStatus={blockchain.Status}/>
            </Grid>
          ))
        }
      </Grid>
    </Grid>
  );
};

export const BlockchainsStatusBar = withStyles(BlockchainsStatusBarView, style);
