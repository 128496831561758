import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { BottomNavigation, Grid, TextField, Typography } from '@mui/material';

import { LinearProgressBar } from 'ui/linear-progress-bar';
import { EditPen } from 'ui/edtit-pen';
import { NumberFormatInt } from 'ui';
import { getMSFromMin, getMinFromMS, useInterval, useLocalStorage } from 'lib';
import { transactionsReportSelector } from 'features/report/selectors';
import { blockchainsSelector } from 'features/dictionaries/selectors';
import { getBlockchains } from 'features/dictionaries/effects';
import { clientsReportSelector } from 'features/clients/selectors';

import { KeyboardEventKey } from '../models';

import { BlockchainsStatusBar } from './blockchains-status-bar';

const styles = theme => ({
  content: {
    height: '30px',
    padding: '0 15px',
  },
  linearProgress: {
    width: '20vh',
    maxWidth: '160px',
  },
});

const mapStateToProps = state => ({
  blockchains: blockchainsSelector(state),
  clientsReport: clientsReportSelector(state),
  transactionsReport: transactionsReportSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getBlockchains: () => dispatch(getBlockchains),
});
const timer = 600000;
const FooterView = ({
  classes,
  getBlockchains,
  blockchains,
  clientsReport,
  transactionsReport,
}) => {
  const [ minutes, setMinutes ] = useLocalStorage('blockchainsFreq', getMinFromMS(timer));
  const [ tempTime, setTempTime ] = useState('');
  const [ edit, setEdit ] = useState(false);

  useEffect(() => {
    getBlockchains();
  }, [ getBlockchains ]);

  useInterval(() => {
    getBlockchains();
  }, getMSFromMin(minutes));

  const aceptTime = () => {
    setEdit(false);
    if (!+tempTime) return;
    setMinutes(tempTime);
  };

  const onBlur = () => {
    setEdit(false);
    if (!+tempTime) return;
  };

  return (
    <BottomNavigation className={classes.content}>
      <Grid
        container
        alignItems='center'
        sx={{ maxWidth: '240px', marginRight: '16px' }}
        wrap='nowrap'
        onKeyDown={(e) => {
          if (e.key === KeyboardEventKey.ENTER) aceptTime();
          if (e.key === KeyboardEventKey.ESC) setEdit(false);
        }}>
        <Typography style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>request frequency (min): {!edit ? minutes : ''}</Typography>
        {!edit ? <EditPen onClick={() => setEdit(true)}/> : null}
        {edit ?
          <TextField
            autoFocus
            InputProps={{
              inputComponent: NumberFormatInt,
              disableUnderline: true,
            }}
            value={minutes}
            variant='standard'
            onBlur={onBlur}
            onChange={e => setTempTime(e.target.value)}
          /> : null}

      </Grid>
      <Grid container alignItems='center' gap='8px' wrap='nowrap'>
        {clientsReport.active ? <LinearProgressBar classes={classes} progress={clientsReport.progress} title='Clients report'/> : null}
        {transactionsReport.active ? <LinearProgressBar classes={classes} progress={transactionsReport.progress} title='Transactions report'/> : null}
      </Grid>
      {blockchains?.length > 0 && <BlockchainsStatusBar blockchains={blockchains} classes={classes}/>}
    </BottomNavigation>
  );
};

export const Footer = connect(mapStateToProps, mapDispatchToProps)(withStyles(FooterView, styles));

