import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

export const LinearProgressBar = ({ progress, title, classes }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <Box sx={{ minWidth: 'fit-content' }}>
      <Typography
        variant="body2"
      >
        {title}
      </Typography>
    </Box>
    <Box className={classes.linearProgress}>
      <LinearProgress sx={{ width: '100%' }} value={progress} variant="determinate" />
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography
        sx={{ color: 'text.secondary' }}
        variant="body2"
      >
        {progress}%
      </Typography>
    </Box>
  </Box>
);
