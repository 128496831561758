import { createSelector } from 'reselect';

export const reportRootSelector = createSelector(
  root => root.report,
  report => report
);

export const accountStatementReportSelector = createSelector(reportRootSelector, report => report.accountStatementReport);

export const reportStatusSelector = createSelector(reportRootSelector, report => report.fetchReport.status);
export const transactionsReportStatusSelector = createSelector(reportRootSelector, report => report.fetchTransactionReport.status);
export const reportBarsDSSelector = createSelector(reportRootSelector, report => report.fetchReportBarsDS.status);
export const transactionsReportSelector = createSelector(reportRootSelector, report => report.transactionsReport);
