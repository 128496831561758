import { handleFetching } from 'symbiote-fetching';

import { actions } from './symbiotes';
import { getResponse } from './response';
import { commonApi } from './api';

export const uploadFile = (query, accessToken, appDeviceId) =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(commonApi.uploadFile, query, accessToken, appDeviceId);
        return getResponse({ status: response.status, ...(response[0] || response) });
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const uploadWalletFile = (query, accessToken, appDeviceId) =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(commonApi.uploadWalletFile, query, accessToken, appDeviceId);
        return getResponse({ status: response.status, ...(response[0] || response) });
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const uploadUIFile = (query, accessToken, appDeviceId) =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(commonApi.uploadUIFile, query, accessToken, appDeviceId);
        return getResponse({ status: response.status, ...(response[0] || response) });
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const deleteFile = name =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(commonApi.deleteFile, name);
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getFile = name =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(commonApi.getFile, name);
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });
